import React, { useState, useEffect} from 'react';
import styles from './elems.module.css';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMongo, checkProp, isDraftObject, getCurrency, getAddedTax } from '../../../../../utils/check';
import Input from '../../../../inputs/input';
import InputAmount from '../../../../inputs/inputAmount';
import Checkbox from '../../../../inputs/checkBox';
import InputSelect from '../../../../inputs/inputSelect';
import InputTextArea from '../../../../inputs/inputTextArea';
// import AssetOrganizationItem from '../../view/elems/assetOrganizationItem';
import Button from '../../../../elements/buttons/button';
// import AssetHolder from './organizations/orgAssetItem/orgAssetItem';
import { announcementCreate, announcementUpdate } from '../../../../../redux/asset/assetActions';
import { getMyRedemption, getMyRedemptionDraft, postMyRedemptionDraft, getMyRedemptionDraftContract, getMyRedemptionContract } from '../../../../../utils/endPoints';
import Loader from '../../../../loader/loader';
import AnnouncementDecisionsEdit from './decisions/decisionsEdit';
import BankAccounts from './bankAccounts/bankAccounts';
import InputDate from '../../../../inputs/inputDate';
import RelatedEntities from '../../../announcement/view/elems/relatedEntities'

const RedemptionGeneralCreate = ( { data, create } ) => {
    const userProfiles = useSelector(state => state.start.userProfiles);
    const token = useSelector(state => state.start.token);
    // const assetItem = useSelector(state => state.asset.assetItem)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const assetObject = location.state

    const [loader, setLoader] = useState( false )

    const [title, setTitle] = useState( () => {
        if( create && !!assetObject ) return isMongo( assetObject.title )
        else return !!data?.title ? isMongo(data.title) : ''
    })
    const [description, setDescription] = useState( ()=> {
        if( create && !!assetObject ) return isMongo( assetObject.description )
        else return !!data?.description ? isMongo(data.description) : ''
    })
    const [ pipelineMethod, setPipelineMethod ] = useState( !!data?.pipelineMethod ? data.pipelineMethod : 'redemption' )
    const [ saleCondition, setSaleCondition ] = useState( !!data?.saleCondition ? data.saleCondition : null )
    const [ bankAccounts, setBankAccounts] = useState( !!data?.bankAccounts ? data.bankAccounts : [] )

    const [decisions, setDecisions] = useState( !!data?.decisions ? data.decisions : [{
            decisionTitle: '',
            decisionNumber: '',
            decisionDate: null,
            decisionOf: 'redemption'
        }] )

    const [relatedEntities, setRelatedEntities] = useState( ()=> {
        if( create && !!assetObject ) return [{type: 'asset', _id: assetObject.id, objectId: assetObject.assetId, archiveId: null, url: null }]
        else return !!data?.relatedEntities ? data.relatedEntities : null
    })
    // const [assetCustodian, setAssetCustodian] = useState( null )


    const [ contractNumber, setContractNumber ] = useState( !!data?.contracts[0]?.contractNumber ? data.contracts[0].contractNumber : null )
    const [ contractTitle, setContractTitle] = useState( !!data?.contracts[0]?.title?.uk_UA ? data.contracts[0].title.uk_UA : null )
    const [ contractDescription, setContractDescription] = useState( !!data?.contracts[0]?.description?.uk_UA ? data.contracts[0].description.uk_UA : null )
    const [ contractValue, setContractValue ] = useState( !!!!data?.contracts[0]?.value ? data.contracts[0].value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: false } )
    const [ contractTotalValue, setContractTotalValue ] = useState( !!!!data?.contracts[0]?.contractTotalValue ? data.contracts[0].contractTotalValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: false } )
    const [ contractReducedValue, setContractReducedValue ] = useState( !!!!data?.contracts[0]?.reducedValue ? data.contracts[0].reducedValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: false } )
    const [ contractValueAddedTaxCharged, setContractValueAddedTaxCharged ] = useState( !!data?.contracts[0]?.valueAddedTaxCharged ? data.contracts[0].valueAddedTaxCharged : false )
    const [ contractDateSigned, setContractDateSigned] = useState( !!data?.contracts[0]?.dateSigned ? data.contracts[0].dateSigned : '' )
    const [ contractTimeDateFrom, setContractTimeDateFrom] = useState( !!data?.contracts[0]?.contractTime?.dateFrom ? data.contracts[0].contractTime.dateFrom : '' )
    const [ contractTimeDateTill, setContractTimeDateTill] = useState( !!data?.contracts[0]?.contractTime?.dateTill ? data.contracts[0].contractTime.dateTill : '' )

    const [ contractBuyersName, setContractBuyersName] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.name?.uk_UA ? data.contracts[0].buyers[0].contactPoint.name.uk_UA : null )
    
    const [ contractBuyersIdentifierScheme, setContractBuyersIdentifierScheme] = useState( !!data?.contracts[0]?.buyers[0]?.identifier?.scheme ? data.contracts[0].buyers[0].identifier.scheme : null )
    const [ contractBuyersIdentifierLegalName, setContractBuyersIdentifierLegalName] = useState( !!data?.contracts[0]?.buyers[0]?.identifier?.legalName?.uk_UA ? data.contracts[0].buyers[0].identifier.legalName.uk_UA : null )
    const [ contractBuyersIdentifierId, setContractBuyersIdentifierId] = useState( !!data?.contracts[0]?.buyers[0]?.identifier?.id ? data.contracts[0].buyers[0].identifier.id : null )

    const [ contractBuyersAddressCountryName, setContractBuyersAddressCountryName] = useState('Україна')
    const [ contractBuyersAddressRegion, setContractBuyersAddressRegion] = useState( !!data?.contracts[0]?.buyers[0]?.address.region?.uk_UA ? data.contracts[0].buyers[0].address.region.uk_UA : null )
    const [ contractBuyersAddressLocality, setContractBuyersAddressLocality] = useState( !!data?.contracts[0]?.buyers[0]?.address.locality?.uk_UA ? data.contracts[0].buyers[0].address.locality.uk_UA : null )
    const [ contractBuyersAddressStreetAddress, setContractBuyersAddressStreetAddress] = useState( !!data?.contracts[0]?.buyers[0]?.address.streetAddress?.uk_UA ? data.contracts[0].buyers[0].address.streetAddress.uk_UA : null )
    const [ contractBuyersAddressPostalCode, setContractBuyersAddressPostalCode] = useState( !!data?.contracts[0]?.buyers[0]?.address.postalCode ? data.contracts[0].buyers[0].address.postalCode : null )

    const [ contractBuyersRepresentativeInfo, setContractBuyersRepresentativeInfo] = useState( !!data?.contracts[0]?.buyers[0]?.representativeInfo ? data.contracts[0].buyers[0].representativeInfo : null )

    const [ contractBuyersContactPointName, setContractBuyersContactPointName] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.name?.uk_UA ? data.contracts[0].buyers[0].contactPoint.name.uk_UA : null )
    const [ contractBuyersContactPointEmail, setContractBuyersContactPointEmail] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.email ? data.contracts[0].buyers[0].contactPoint.email : null )
    const [ contractBuyersContactPointTelephone, setContractBuyersContactPointTelephone] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.telephone ? data.contracts[0].buyers[0].contactPoint.telephone : null )
    const [ contractBuyersContactPointFaxNumber, setContractBuyersContactPointFaxNumber] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.faxNumber ? data.contracts[0].buyers[0].contactPoint.faxNumber : null )
    const [ contractBuyersContactPointUrl, setContractBuyersContactPointUrl] = useState( !!data?.contracts[0]?.buyers[0]?.contactPoint?.url ? data.contracts[0].buyers[0].contactPoint.url : null )

    useEffect(() => {
      if (!!userProfiles && userProfiles?.organization ){
        // setAssetCustodian( userProfiles.organization)
      }
    }, [userProfiles])

    // useEffect(() => {
    //     // let tmp = +value.amount * 0.0
    //     let tmp = (value.amount * 0.01).toFixed(2)
    //     setDutchStepValue( { currency: 'UAH', amount: tmp } )
    //     // tmp = tmp.toFixed(2)
    //     // if ( guarantee.amount === '') setGuarantee( prev => ({...prev, amount: tmp }))
    // }, [value])
    


    function createObjectData(){
        let nameBank = ''
        if(bankAccounts[0].accounts[0].bankName.hasOwnProperty('uk_UA')){
            nameBank = bankAccounts[0].accounts[0].bankName
        }else{
            nameBank = { uk_UA: bankAccounts[0].accounts[0].bankName }
        }
        let nameDesk = ''
        if(bankAccounts[0].accounts[0].description.hasOwnProperty('uk_UA')){
            nameDesk = bankAccounts[0].accounts[0].description
        }else{
            nameDesk = { uk_UA: bankAccounts[0].accounts[0].description }
        }
        let body = {
            title: { uk_UA: title },
            description: { uk_UA: description },
            pipelineMethod: pipelineMethod,
            saleCondition: saleCondition,
            relatedEntities: relatedEntities,
            decisions: decisions,
            bankAccounts: bankAccounts !== null && bankAccounts.length > 0
                ? [{
                    accountType: bankAccounts[0].accountType,
                    accounts: [{
                        bankName: nameBank,
                        description: nameDesk,
                        accountHolder: bankAccounts[0].accounts[0].accountHolder,
                        accountIdentifications: bankAccounts[0].accounts[0].accountIdentifications,
                        currency : bankAccounts[0].accounts[0].currency
                    }]
                }]
                : null
        }
        let bodyContract = {
            contractNumber: contractNumber === null || contractNumber === '' ? null : contractNumber,
            title: contractTitle === null || contractTitle === '' ? null : { uk_UA: contractTitle },
            description: contractDescription === null || contractDescription === '' ? null : { uk_UA: contractDescription },
            
            value: contractValue,
            contractTotalValue: contractTotalValue,
            reducedValue: contractReducedValue,
            valueAddedTaxCharged: contractValueAddedTaxCharged,
            
            buyers: [
                {
                    identifier: contractBuyersIdentifierScheme === null || contractBuyersIdentifierScheme === '' || contractBuyersIdentifierLegalName === null || contractBuyersIdentifierLegalName === '' || contractBuyersIdentifierId === null || contractBuyersIdentifierId === '' ? null : {
                        scheme: contractBuyersIdentifierScheme,
                        legalName: { uk_UA: contractBuyersIdentifierLegalName },
                        id: contractBuyersIdentifierId,
                    },
                    address: {
                        countryName: contractBuyersAddressCountryName === null || contractBuyersAddressCountryName === '' ? null : { uk_UA: contractBuyersAddressCountryName },
                        region: contractBuyersAddressRegion === null || contractBuyersAddressRegion === '' ? null : { uk_UA: contractBuyersAddressRegion },
                        locality: contractBuyersAddressLocality === null || contractBuyersAddressLocality === '' ? null : { uk_UA: contractBuyersAddressLocality },
                        streetAddress: contractBuyersAddressStreetAddress === null || contractBuyersAddressStreetAddress === '' ? null : { uk_UA: contractBuyersAddressStreetAddress },
                        postalCode: contractBuyersAddressPostalCode === null || contractBuyersAddressPostalCode === '' ? null : contractBuyersAddressPostalCode,
                    },
                    representativeInfo: contractBuyersRepresentativeInfo === null || contractBuyersRepresentativeInfo === '' ? null : contractBuyersRepresentativeInfo,
                    contactPoint: {
                        name: contractBuyersContactPointName === null || contractBuyersContactPointName === '' ? null : { uk_UA: contractBuyersContactPointName },
                        email: contractBuyersContactPointEmail === null || contractBuyersContactPointEmail === '' ? null : contractBuyersContactPointEmail,
                        telephone: contractBuyersContactPointTelephone === null || contractBuyersContactPointTelephone === '' ? null : contractBuyersContactPointTelephone,
                        faxNumber: contractBuyersContactPointFaxNumber === null || contractBuyersContactPointFaxNumber === '' ? null : contractBuyersContactPointFaxNumber,
                        url: contractBuyersContactPointUrl === null || contractBuyersContactPointUrl === '' ? null : contractBuyersContactPointUrl,
                    }
                }
            ],
            
            dateSigned: contractDateSigned === null || contractDateSigned === '' ? null : contractDateSigned,
            contractTime: (contractTimeDateFrom === null || contractTimeDateFrom === '') && (contractTimeDateTill === null || contractTimeDateTill === '') ? null : {
                dateFrom: contractTimeDateFrom !== null &&  contractTimeDateFrom !== '' ? contractTimeDateFrom : null,
                dateTill: contractTimeDateTill !== null && contractTimeDateTill !== '' ? contractTimeDateTill : null
            }
        }
        function setNavigate(id){
            return navigate(`/asset/redemption/edit/${id}`)
        }
        if ( create ) {
            body.contracts = [bodyContract]
            dispatch( announcementCreate( `${postMyRedemptionDraft}/jrs/create`, JSON.stringify(body), token.access_token, setNavigate ) )
            setTimeout( ()=> setLoader(false), 500)
            setLoader(true)
        } else {
            const url = ( id ) => isDraftObject(id) ? getMyRedemptionDraft(id) : getMyRedemption(id)
            dispatch( announcementUpdate( url(data.id) , JSON.stringify(body), token.access_token))
            const urlContract = (id, contractId) =>  isDraftObject(id) ? getMyRedemptionDraftContract(id, contractId) : getMyRedemptionContract(id, contractId)
            dispatch( announcementUpdate( urlContract(data.id, data.contracts[0].id) , JSON.stringify(bodyContract), token.access_token))
            setTimeout( ()=> setLoader(false), 300)
            setLoader(true)
            if ( data.status === 'pending' ) navigate(`/asset/my_redemptions`)
        }
        window.scrollTo(0, 0)
    }

    if ( loader ) return <Loader />
    // if ( data.status !== 'pending' && !location?.state ) return navigate(`/asset/my_announcements`)

    return (
    <div className={styles.blockWrap}>
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Загальні характеристики</div>
            <Input 
                label='Назва' 
                value={title}
                onChange={ e => setTitle(e)}
                reqiured
            />
            <InputTextArea 
                label='Опис'
                value={description}
                onChange={ e => setDescription(e)}
                reqiured
            />
            { ( process.env.REACT_APP_END_POINT_BD.includes('dev') || process.env.REACT_APP_END_POINT_BD.includes('sandbox') ) &&
                <InputSelect 
                    label='pipelineMethod' // "Value must be one of ['state', 'municipal', 'compatible']
                    value={ pipelineMethod } 
                    onChange={ e => setPipelineMethod(e)}
                    dictionary={true}
                    options={[
                        {name: "redemption",description: {uk_UA: "redemption"}},
                        {name: "redemption-fast",description: {uk_UA:"redemption-fast"}},
                        {name: "redemption-manual",description: {uk_UA:"redemption-manual"}},
                        {name: "redemption-ultrafast",description: {uk_UA:"redemption-ultrafast"}},
                    ]}
                    reqiured
                    infoBlock={
                    <>
                        {/* <h4>УВАГА!! Обрати можна лише при створенні, не редагується!!</h4> */}
                        <li><b>redemption</b> - rectificationPeriod: 2дні, процедура smallPrivatization-english</li>
                        <li><b>redemption-fast</b> - rectificationPeriod: 2хв, процедура smallPrivatization-english-fast</li>
                        <li><b>redemption-manual</b> - rectificationPeriod: 10хв, процедура smallPrivatization-english-manual</li>
                        <li><b>redemption-ultrafast</b> - rectificationPeriod: 5сек, процедура smallPrivatization-english-fast</li>
                    </>
                    }
                    descr={<span style={{ color: 'var(--red)', fontWeight: '600'}}>УВАГА!! Обрати можна лише при створенні, не редагується!!</span>}
                    disabled={ create ? false : true }
                />
            }
            <InputSelect 
                label='Наявність умов продажу'
                value={ saleCondition }
                onChange={ e => setSaleCondition(e) }
                options={[
                    { value: "yes", name: "Так" },
                    { value: "no", name: "Ні" }
                ]}
                reqiured
            />
        </div>

        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Рішення органу приватизації про затвердження умов продажу *</div>
            <AnnouncementDecisionsEdit data={ decisions } setData={ setDecisions } />
        </div>

        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Банківські рахунки організатора *</div>
            <BankAccounts bankAccounts={ bankAccounts } setBankAccounts={ setBankAccounts } />
        </div>

        <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>Контракт *</div>
            <Input 
                label='Номер договору' 
                value={contractNumber}
                onChange={ e => setContractNumber(e)}
                reqiured
            />
            <Input 
                label='Назва договору' 
                value={contractTitle}
                onChange={ e => setContractTitle(e)}
                reqiured
            />
            <Input 
                label='Опис договору' 
                value={contractDescription}
                onChange={ e => setContractDescription(e)}
                reqiured
            />
            <InputAmount 
                title='Вартість договору'
                value={ contractValue }
                onChange={ setContractValue }
                tax={ true }
                required
                // enableCurrent
            />
            <InputAmount 
                title='Заключна вартість договору'
                value={ contractTotalValue }
                onChange={ setContractTotalValue }
                tax={ true }
                // enableCurrent
            />
            <InputAmount 
                title='Ціна, зменшена на суму компенсації'
                value={ contractReducedValue }
                onChange={ setContractReducedValue }
                tax={ true }
                // enableCurrent
            />
            <Checkbox
                value={ contractValueAddedTaxCharged }
                onChange={setContractValueAddedTaxCharged}
                label='На фінальну суму нараховується ПДВ'
                // disabled
            />
            <InputDate
                data={{
                    label: 'Дата підписання договору',
                    value: contractDateSigned,
                    onChange: e => setContractDateSigned( e ),
                    reqiured: true,
                    time: true
                }}
            />

            <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Переможець *</div>
                <Input 
                    label='Повна юридична назва організації або ПІБ' 
                    value={ contractBuyersName }
                    onChange={ e => setContractBuyersName(e)}
                    disabled
                />

                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Ідентифікатори організації або особи *</div>
                    <InputSelect 
                        label='Ідентифікатори організації'
                        value={ contractBuyersIdentifierScheme }
                        onChange={ e => setContractBuyersIdentifierScheme(e)}
                        options={[
                            { value: "UA-EDR", name: "ЄДРПОУ" },
                            { value: "UA-IPN", name: "ІПН/РНОКПП" },
                            { value: "UA-PASSPORT", name: "Паспорт" },
                            { value: "UA-ID-CARD", name: "ID-карта" },
                            { value: "UA-IPN-FOP", name: "ІПН/РНОКПП (ФОП)" }
                        ]}
                        reqiured
                    />
                    <Input 
                        label='Повна юридична назва організації' 
                        value={ contractBuyersIdentifierLegalName }
                        onChange={ e => setContractBuyersIdentifierLegalName(e)}
                        reqiured
                    />
                    <Input 
                        label='Код ЄДРПОУ або ІПН або паспорт' 
                        value={ contractBuyersIdentifierId }
                        onChange={ e => setContractBuyersIdentifierId(e)}
                        reqiured
                    />
                </div>

                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Адреса *</div>
                    <Input 
                        label='Країна' 
                        value={ contractBuyersAddressCountryName }
                        onChange={ e => setContractBuyersAddressCountryName(e)}
                        reqiured
                        disabled
                    />
                    <InputSelect 
                        label='Область'
                        value={ contractBuyersAddressRegion }
                        onChange={ e => setContractBuyersAddressRegion(e) }
                        options={[
                            { value: "Автономна Республіка Крим", name: "Автономна Республіка Крим" },
                            { value: "Вінницька область", name: "Вінницька область" },
                            { value: "Волинська область", name: "Волинська область" },
                            { value: "Дніпропетровська область", name: "Дніпропетровська область" },
                            { value: "Донецька область", name: "Донецька область" },
                            { value: "Житомирська область", name: "Житомирська область" },
                            { value: "Закарпатська область", name: "Закарпатська область" },
                            { value: "Запорізька область", name: "Запорізька область" },
                            { value: "Івано-Франківська область", name: "Івано-Франківська область" },
                            { value: "Київська область", name: "Київська область" },
                            { value: "Київ", name: "Київ" },
                            { value: "Кіровоградська область", name: "Кіровоградська область" },
                            { value: "Луганська область", name: "Луганська область" },
                            { value: "Львівська область", name: "Львівська область" },
                            { value: "Миколаївська область", name: "Миколаївська область" },
                            { value: "Одеська область", name: "Одеська область" },
                            { value: "Полтавська область", name: "Полтавська область" },
                            { value: "Рівненська область", name: "Рівненська область" },
                            { value: "Севастополь", name: "Севастополь" },
                            { value: "Сумська область", name: "Сумська область" },
                            { value: "Тернопільська область", name: "Тернопільська область" },
                            { value: "Харківська область", name: "Харківська область" },
                            { value: "Херсонська область", name: "Херсонська область" },
                            { value: "Хмельницька область", name: "Хмельницька область" },
                            { value: "Черкаська область", name: "Черкаська область" },
                            { value: "Чернівецька область", name: "Чернівецька область" },
                            { value: "Чернігівська область", name: "Чернігівська область" },
                        ]}
                        reqiured
                    />
                    <Input 
                        label='Населений пункт' 
                        value={ contractBuyersAddressLocality }
                        onChange={ e => setContractBuyersAddressLocality(e)}
                        reqiured
                    />
                    <Input 
                        label='Адреса' 
                        value={ contractBuyersAddressStreetAddress }
                        onChange={ e => setContractBuyersAddressStreetAddress(e)}
                        reqiured
                    />
                    <Input 
                        label='Поштовий індекс' 
                        value={ contractBuyersAddressPostalCode }
                        onChange={ e => setContractBuyersAddressPostalCode(e)}
                    />
                </div>

                <Input 
                    label='Інформація щодо підтвердження повноважень' 
                    value={ contractBuyersRepresentativeInfo }
                    onChange={ e => setContractBuyersRepresentativeInfo(e)}
                />

                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Контактна особа *</div>
                    <Input 
                        label='ПІБ'
                        value={ contractBuyersContactPointName }
                        onChange={ e => setContractBuyersContactPointName(e)}
                        reqiured
                    />
                    <Input 
                        label='Адреса електронної пошти'
                        value={ contractBuyersContactPointEmail }
                        onChange={ e => setContractBuyersContactPointEmail(e)}
                        reqiured
                    />
                    <Input 
                        label='Номер телефону'
                        value={ contractBuyersContactPointTelephone }
                        onChange={ e => setContractBuyersContactPointTelephone(e)}
                        reqiured
                    />
                    <Input 
                        label='Номер факсу' 
                        value={ contractBuyersContactPointFaxNumber }
                        onChange={ e => setContractBuyersContactPointFaxNumber(e)}
                    />
                    <Input 
                        label='Веб адреса' 
                        value={ contractBuyersContactPointUrl }
                        onChange={ e => setContractBuyersContactPointUrl(e)}
                    />
                </div>
            </div>

            <InputDate
                data={{
                    label: 'Термін виконання договору Період з',
                    value: contractTimeDateFrom,
                    onChange: e => setContractTimeDateFrom( e ),
                    time: true
                }}
            />
            <InputDate
                data={{
                    label: 'Термін виконання договору Період по',
                    value: contractTimeDateTill,
                    onChange: e => setContractTimeDateTill( e ),
                    time: true
                }}
            />
        </div>

        {relatedEntities !== null 
            ? <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Список пов`язаних сутностей</div>
                <RelatedEntities data={ relatedEntities } />
            </div>
            : null
        }
        
        <Button
            onClick={ createObjectData }
        >
            { !!data ? 'Зберегти' : 'Зберегти та продовжити' }
        </Button>
    </div>
    )
}

export default RedemptionGeneralCreate