import React, { useEffect, useState } from 'react';
import styles from './chooseOrganization.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../../../../../../loader/loader.js';
import { 
    // setPopupChooseOrganization, 
    // setPopupAddOrganization, 
    organizationsListGet, 
    // organizationPost, 
    organizationDelete,
} from '../../../../../../../../../redux/actions/organizations/organizations.js';
// import { relatedOrganizationsDelete, objectGet } from '../../../../../../../../../redux/actions/object/object'
import { checkProp, isMongo } from '../../../../../../../../../utils/check';
import { getUserOrganizations } from '../../../../../../../../../utils/endPoints';
import AddOrganizationNEW from '../addOrganizationNEW/addOrganizationNEW';
import ChooseOrganizationItem from './chooseItem';

const ChooseOrganizationNEW = ({ data, setData, close, ...props}) => {

    const [popupAdd, setPopupAdd] = useState( false )

    const dispatch = useDispatch();
    const token = useSelector(state => state.start.token);
    const userProfiles = useSelector(state => state.start.userProfiles);
    const organizationsGetList = useSelector(state => state.start.organizationsGetList);
    // const pageCreate = useSelector(state => state.start.objectPage);

    useEffect(() => {
        if(organizationsGetList === null) {
            dispatch( organizationsListGet( getUserOrganizations, token.access_token));
        }
    }, []);

    function setOrganizationsList() {
        let organizationsList = [];
        if ( !!userProfiles?.organization ) {
            organizationsList[0] = <li key={1} 
                                        onClick={() => {
                                        // setData(userProfiles.organization)
                                        organizationDataPrepare( userProfiles.organization )
                                        close()
                                    }}>
                                        <span className={styles.orgName}>{ isMongo(userProfiles.organization.name) }</span>
                                        <span className={styles.orgId}>{userProfiles.organization.id}</span>
                                    </li>
            if ( !!organizationsGetList ) {
                organizationsList[1] = organizationsGetList.map( (item, count) => { 
                    return ( <ChooseOrganizationItem 
                                item={item} 
                                organizationDataPrepare={organizationDataPrepare} 
                                close={close}
                                deleteOrganization={deleteOrganization}
                                setData={setData} 
                            />
                    )
                });
            }
        } else if (!organizationsGetList) {
            return <Loader />
        }
        return organizationsList;
    }

    function organizationDataPrepare( data ){
        const body = {
            name: { uk_UA: isMongo(data.name) },
            identifier: {
                id: data.identifier.identifier === null || data.identifier.identifier === '' ? null : data.identifier.identifier,
                scheme: data.identifier.scheme === null || data.identifier.scheme === '' ? null : data.identifier.scheme,
                legalName: { uk_UA: isMongo(data.identifier.legalName) }
            },
            address: {
                streetAddress: { uk_UA: isMongo(data.address.streetAddress) },
                locality: { uk_UA: isMongo(data.address.locality) },
                region: { uk_UA: isMongo(data.address.region) },
                postalCode: data.address.postalCode,
                countryName: { uk_UA: 'Україна' }
            },
            contactPoint: {
                name: { uk_UA: isMongo(data.contactPoint.name) },
                email: data.contactPoint.email === null || data.contactPoint.email === '' ? null : data.contactPoint.email,
                telephone: data.contactPoint.telephone === null || data.contactPoint.telephone === '' ? null : data.contactPoint.telephone,
                faxNumber: data.contactPoint.faxNumber === null || data.contactPoint.faxNumber === '' ? null : data.contactPoint.faxNumber,
                url: data.contactPoint.url === null || data.contactPoint.url === '' ? null : data.contactPoint.url
            },
            representativeInfo: checkProp(data, 'representativeInfo') ? data.representativeInfo : null
        }
        setData(body)
    }
    
    function deleteOrganization(id) {
        let action = () => {
            dispatch( organizationsListGet( getUserOrganizations, token.access_token ) );
        }
        dispatch( organizationDelete(`${getUserOrganizations}/${id}`, token.access_token, action) );
    }

    return(
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <div className={styles.popupTitle}>Оберіть організацію</div>
                    <button className={styles.closeButton} onClick={ close }></button>
                    <div className={styles.list_wrap}>
                        <ul className={styles.list}>
                            { !!userProfiles && !!organizationsGetList ? setOrganizationsList() : <Loader small/> }
                        </ul>
                    </div>
                    <button className={styles.buttonAddNew}
                        onClick={() => setPopupAdd( true )}
                    >
                        + Додати новий
                    </button>
                </div>
            </div>
            { popupAdd && <AddOrganizationNEW setData={ setData } close={ ()=> setPopupAdd(false) } /> }
        </div>
    );
}

export default ChooseOrganizationNEW;