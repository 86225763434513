import React,{ useState, useEffect } from 'react';
import styles from './itemProps.module.css'
import {useSelector, useDispatch} from 'react-redux';
import { isMongo, getAdditionalClassificatorDM, dictionaryGet } from '../../../../../../../../../utils/check';
import Input from '../../../../../../../../inputs/input';
import InputTextArea from '../../../../../../../../inputs/inputTextArea';
import InputSelect from '../../../../../../../../inputs/inputSelect';
import Checkbox from '../../../../../../../../inputs/checkBox';
// import InputDate from '../../../../../../../../inputs/inputDate';
import Button from '../../../../../../../../elements/buttons/button';
import Localization from '../localization/localization';
import Loader from '../../../../../../../../loader/loader';
import { objectItemCreate, objectItemUpdate } from '../../../../../../../../../utils/objects/objectsUtils';

const JointPropertyComplexPropsEdit =({ data, classification, close })=> {
    const objectData = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);

    const objectEncumbrancesList = useSelector(state => state.start.objectEncumbrancesList)
    const objectOwnershipTypeList = useSelector(state => state.start.objectOwnershipTypeList)
    // const assetRegistrationStatus = useSelector(state => state.start.assetRegistrationStatus)
    const dispatch = useDispatch()

    const [loader, setLoader] = useState( false )

    // const [additionalClassificator, setAdditionalClassificator] = useState( data?.additionalClassifications[0]?.id ? data.additionalClassifications[0].id : null )
    const [title, setTitle] = useState( data?.basicInfo.title ? isMongo(data.basicInfo.title) : null )
    const [description, setDescription] = useState( data?.basicInfo.description ? isMongo(data.basicInfo.description) : null )
    const [location, setLocation] = useState( data?.placing.location ? data.placing.location : null )
    const [address, setAdress] = useState( data?.placing.address ? data.placing.address : null )

    const [quantityAndNomenclature, setQuantityAndNomenclature] = useState( data?.jpcProps?.quantityAndNomenclature ?  data.jpcProps.quantityAndNomenclature : null)
    const [workplacesQuantity, setWorkplacesQuantity] = useState( data?.jpcProps?.workplacesQuantity ?  data.jpcProps.workplacesQuantity : null)
    const [buildingsInformation, setBuildingsInformation] = useState( data?.jpcProps?.buildingsInformation ?  data.jpcProps.buildingsInformation : null)
    const [landPlotInformation, setLandPlotInformation] = useState( data?.jpcProps?.landPlotInformation ?  data.jpcProps.landPlotInformation : null)
    const [mainObligations, setMainObligations] = useState( data?.jpcProps?.mainObligations ?  data.jpcProps.mainObligations : null)

    const [cadastral, setCadastral] = useState( data?.jpcProps?.landProps?.cadastralNumber ? data.jpcProps.landProps.cadastralNumber : null)
    const [landArea, setLandArea] = useState( data?.jpcProps?.landProps?.landArea ? data.jpcProps.landProps.landArea : null)
    const [ownershipType, setOwnershipType] = useState( data?.jpcProps?.landProps?.ownershipType ? data.jpcProps.landProps.ownershipType : null)

    const [encumbrances, setEncumbrances] = useState( data?.jpcProps?.landProps?.encumbrances ? data.jpcProps.landProps.encumbrances : null)
    const [jointOwnership, setJointOwnership] = useState( data?.jpcProps?.landProps?.jointOwnership ? data.jpcProps.landProps.jointOwnership : null)
    const [utilitiesAvailability, setUtilitiesAvailability] = useState( data?.jpcProps?.landProps?.utilitiesAvailability ? data.jpcProps.landProps.utilitiesAvailability : null)

    const [landIntendedUse, setLandIntendedUse] = useState( data?.jpcProps?.landProps?.landIntendedUse ? data.jpcProps.landProps.landIntendedUse : null)

    const [hasEncumbrances, setHasEncumbrances] = useState( data?.jpcProps?.landProps?.hasEncumbrances ? data.jpcProps.landProps.hasEncumbrances : false)
    const [hasJointOwnership, setHasJointOwnership] = useState( data?.jpcProps?.landProps?.hasJointOwnership ? data.jpcProps.landProps.hasJointOwnership : false)
    const [hasUtilitiesAvailability, setHasUtilitiesAvailability] = useState( data?.jpcProps?.landProps?.hasUtilitiesAvailability ? data.jpcProps.landProps.hasUtilitiesAvailability : false)

    // const addClassif = getAdditionalClassificatorDM(classification.id)

        useEffect( () => {
        if ( objectOwnershipTypeList === null ) {
            dispatch( dictionaryGet('ownershipType', 'OBJECT_GET_OWNERSHIP_TYPE') );
        }
        if ( objectEncumbrancesList === null ) {
            dispatch( dictionaryGet('encumbrances', 'OBJECT_GET_ENCUMBRANCES_LIST') );
        }
        // if ( assetRegistrationStatus === null ) {
        //     dispatch( dictionaryGet('registrationStatus', 'ASSETT_GET_REGISTRATION_STATUS') );
        // }
        // setAdditionalClassificator( addClassif.id )
    }, []);

    function itemProps(){
        let body = {
            // itemPropsType: 'jointPropertyComplex',
            quantityAndNomenclature: quantityAndNomenclature !== null && quantityAndNomenclature !== '' ? quantityAndNomenclature : null,
            workplacesQuantity: workplacesQuantity !== null && workplacesQuantity !== '' ? workplacesQuantity : null,
            buildingsInformation: buildingsInformation !== null && buildingsInformation !== '' ? buildingsInformation : null,
            landPlotInformation: landPlotInformation !== null && landPlotInformation !== '' ? landPlotInformation : null,
            mainObligations: mainObligations !== null && mainObligations !== '' ? mainObligations : null,
            landProps:{
                // itemPropsType: 'land',
                landArea: +landArea,
                cadastralNumber: cadastral,
                ownershipType: ownershipType !== null && ownershipType !== '' ? ownershipType : null,
                // jointOwnership: jointOwnership === null ? 'немає' : jointOwnership,
                // encumbrances: encumbrances === null ? 'noEncumbrances' : encumbrances,
                // utilitiesAvailability: utilitiesAvailability === null ? 'немає' : utilitiesAvailability,
                landIntendedUse: landIntendedUse !== null && landIntendedUse !== '' ? landIntendedUse : null,
                hasEncumbrances: hasEncumbrances === true ? true : false,
                hasJointOwnership: hasJointOwnership === true ? true : false,
                hasUtilitiesAvailability: hasUtilitiesAvailability === true ? true : false,
            }
        }
        body = fHasEncumbrances(body)
        body = fHasJointOwnership(body)
        body = fHasUtilitiesAvailability(body)
        return body
    }
    function fHasEncumbrances(requestBody){
        if(!hasEncumbrances) return requestBody
        requestBody.landProps.encumbrances = encumbrances
        return requestBody
    }
    function fHasJointOwnership(requestBody){
        if(!hasJointOwnership) return requestBody
        requestBody.landProps.jointOwnership = jointOwnership
        return requestBody
    }
    function fHasUtilitiesAvailability(requestBody){
        if(!hasUtilitiesAvailability) return requestBody
        requestBody.landProps.utilitiesAvailability = utilitiesAvailability
        return requestBody
    }

    const requestBody = {
        itemType: 'jointPropertyComplex',
        basicInfo: {
            description: {
                uk_UA: description,
            },
            title: { uk_UA: title, },
            classification: classification,
        },
        placing: { 
            location: location,
            address: address
        },
        jpcProps: itemProps()
    }

    function sendItemData( data ){
        const body = JSON.stringify(requestBody)
        if ( !data ) dispatch ( objectItemCreate(objectData.id, body, token.access_token ))
        if ( !!data ) dispatch ( objectItemUpdate(objectData.id, data.id, body, token.access_token ))
        setLoader( true )
        close()
    }

    function viewButtons(){
        return (
            <div className={styles.lineButtons}>
                <Button
                    data={'Зберегти зміни'}
                    color={'blue'}
                    onClick={
                        () => { sendItemData( data ) }
                    }
                />
                <Button
                    data={'Закрити'}
                    color={'gray'}
                    onClick={ close }
                />
            </div>
        )
    }

    if( loader ) return <Loader />
    return (
        <>
            <Input
                label="Назва об'єкта"
                value={ title }
                onChange={ event => setTitle( event ) }
                reqiured
            />
            <InputTextArea
                value={description}
                // disabled={blokedInput(props, '01')}
                label="Опис об'єкту"
                reqiured
                onChange={ event => setDescription(event) }
            />
            <div className={styles.semiTitle}>Характеристики єдиного майнового комплексу</div>
            <InputTextArea
                value={quantityAndNomenclature}
                // disabled={blokedInput(props, '01')}
                label="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                onChange={ event => setQuantityAndNomenclature(event) }
            />
            <Input
                value={workplacesQuantity}
                // disabled={blokedInput(props, '01')}
                label="Кількість робочих місць, ociб"
                onChange={ event => setWorkplacesQuantity(event) }
                type='number'
            />
            <InputTextArea
                value={buildingsInformation}
                // disabled={blokedInput(props, '01')}
                label="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                onChange={ event => setBuildingsInformation(event) }
            />
            <InputTextArea
                value={landPlotInformation}
                // disabled={blokedInput(props, '01')}
                label="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                onChange={ event => setLandPlotInformation(event) }
            />
            <InputTextArea
                value={mainObligations}
                // disabled={blokedInput(props, '01')}
                label="Основні зобов’язання (договірні та позадоговірні)"
                onChange={ event => setMainObligations(event) }
            />
            <Input
                label="Площа ділянки, га"
                value={landArea}
                onChange={ event => {
                    if(Number(event) <= 60362800){
                        let tmp = event.replace(/,/gi, '.')
                        setLandArea(tmp)
                    }
                }}
                reqiured
            />
            <Input
                label="Кадастровий номер"
                value={cadastral}
                onChange={ event => setCadastral(event) }
                reqiured
                descr={'1234567980:12:123:1234'}
                // disabled={blokedInput(props, '11')}
            />
            <InputSelect
                label={"Оберіть тип власності"}
                value={ ownershipType }
                onChange={ event => setOwnershipType(event) }
                options={ objectOwnershipTypeList }
                dictionary
            />
            <div className={styles.blokInputs}>
            <div className={styles.semiTitle}>Увага! При встановленні відмітки у полі щодо наявності обтяжень/співласників/інженерних мереж обов'язково заповніть відповідні поля з пов'язаною інформацією.</div>
                <Checkbox 
                    value={hasEncumbrances}
                    onChange={setHasEncumbrances}
                >
                    Присутні обтяження
                </Checkbox>
                <InputSelect
                    label="Наявність обтяжень"
                    value={encumbrances}
                    onChange={ event => setEncumbrances(event) }
                    options={ objectEncumbrancesList }
                    dictionary
                    disabled={hasEncumbrances ? false : true}
                />
                <Checkbox 
                    value={hasJointOwnership}
                    onChange={setHasJointOwnership}
                >
                    Наявні співвласники
                </Checkbox>
                <Input
                    label="Наявність співвласників"
                    value={jointOwnership}
                    onChange={ event => setJointOwnership(event) }
                    // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasJointOwnership ? false : true}
                />
                <Checkbox 
                    value={hasUtilitiesAvailability}
                    onChange={setHasUtilitiesAvailability}
                >
                    Інженерні мережі наявні
                </Checkbox>
                <Input
                    label="Наявність інженерних мереж"
                    value={utilitiesAvailability}
                    onChange={ event => setUtilitiesAvailability(event) }
                    // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                    disabled={hasUtilitiesAvailability ? false : true}
                />
            </div>
            <Input
                label="Поточне використання земельної ділянки"
                value={landIntendedUse}
                onChange={ event => setLandIntendedUse(event) }
                // error = {redBlock(props, createItems.landRental.itemProps.cadastralNumber)}
                // disabled={blokedInput(props, '11')}
            />
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} classification={classification} />
            { viewButtons() }
        </>
    )
}

export default JointPropertyComplexPropsEdit