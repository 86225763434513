import React, { useEffect, useState } from 'react';
import styles from './addBuyer.module.css';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../../../inputs/input.js'
import InputSelect from '../../../../../../inputs/inputSelect.js'
import InputTextArea from '../../../../../../inputs/inputTextArea';
import InputAmount from '../../../../../../inputs/inputAmount'
import InputDate from '../../../../../../inputs/inputDate'

import { objectGetUaIdentifiersList, objectGet } from '../../../../../../../redux/actions/object/object';
import { checkProp, isMongo } from '../../../../../../../utils/check';
import { getDictionaryByType, getUserOrganizations, urlPatchMyExecutionStatus } from '../../../../../../../utils/endPoints';
import { axiosPatchAPI } from '../../../../../../../redux/actions/crud';
import { getAPI, postAPI, patchAPI, deleteAPI } from "../../../../../../../redux/actions/crud";

const AddBuyer = ( { data, setData, close, index, ...props } ) => {
    const token = useSelector(state => state.start.token);
    const executionItem = useSelector(state => state.asset.executionItem)
    const dispatch = useDispatch()

    const [ contractNumber, setContractNumber ] = useState( !!data?.contractNumber ? data.contractNumber : '' )
    const [ title, setTitle ] = useState( !!data?.title ? isMongo(data.title) : '' )
    const [ description, setDescription ] = useState(!!data?.description ? isMongo(data.description) : '')
    const [ value, setValue ] = useState(!!data?.value ? data.value : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [ contractTotalValue, setContractTotalValue ] = useState(!!data?.contractTotalValue ? data.contractTotalValue : { currency: 'UAH', amount: '', valueAddedTaxIncluded: true })
    const [ dateSigned, setDateSigned ] = useState( !!data?.dateSigned ? data.dateSigned : null )
    const [ contractTime, setContractTime ] = useState( !!data?.contractTime ? data.contractTime : { dateFrom: null, dateTill: null } )

    async function createObjectData(){
        let body = {
            contractNumber: contractNumber === null || contractNumber === '' ? null : contractNumber,
            title: title === null || title === '' ? null : { uk_UA: title },
            description: description === null || description === '' ? null : { uk_UA: description },
            
            value: value,
            contractTotalValue: contractTotalValue,

            dateSigned: dateSigned === null || dateSigned === '' ? null : dateSigned,
            contractTime: contractTime
        }

        const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/executions/${executionItem.id}/contracts/${data.id}`
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Bearer ' + token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        const json = await response.json();
        if(json.hasOwnProperty("message") !== true){
            await dispatch({type: 'EXECUTION_GET_ID', payload: json})
        }
        //if (response.status === 200 && data.redirect) {
            //window.location.href = data.redirect;
        //}
        //if (response.status === 200 && !!data.navigate) {
            //data.navigate()
        //}
        // dispatch( {type: data.actionType, payload: json} )
        //if (response.status === 200) dispatch( {type: data1.actionType, payload: json} );
        //if (response.status !== 200) dispatch( {type: 'UNIVERSAL_ERROR', payload: `Помилка ${response.status} - ${json.message}` });
        //if (response.status === 200 && data.secondAction) {
            //data.secondAction();
        //}

        close()
    }

    return(
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <div className={styles.popupBody}>
                    <button className={styles.closeButton} 
                        onClick={ close }
                    >
                    </button>
                    <div className={styles.title}>{ !!data ? "Редагування" : "Додавання"} договору</div>

                    <Input 
                        label="Назва договору"
                        value={ title }
                        onChange={ event => setTitle( event ) }
                        //reqiured
                        // disabled={ isDraftObject( data.id ) ? false : true }
                    />
                    <InputTextArea
                        label="Опис договору"
                        value={ description }
                        onChange={ event => setDescription( event ) }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //reqiured
                    />
                    <Input 
                        label="Номер договору"
                        value={ contractNumber }
                        onChange={ event => setContractNumber( event ) }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //reqiured
                    />
                    <InputAmount 
                        title='Вартість договору'
                        value={ value }
                        onChange={ setValue }
                        tax={ true }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        required
                        // enableCurrent
                    />
                    <InputAmount 
                        title='Заключна вартість договору'
                        value={ contractTotalValue }
                        onChange={ setContractTotalValue}
                        tax={ true }
                        // disabled={ isDraftObject( data.id ) ? false : true }
                        //required
                        // enableCurrent
                    />
                    <div><h4>Строк виконання умов приватизації</h4></div>
                        <div className={styles.twoBlock}>
                            <InputDate
                                data={{
                                    label: "Період з",
                                    value: contractTime.dateFrom,
                                    // disabled: isDraftObject( data.id ) ? false : true,
                                    onChange: (e)=> setContractTime( prev => ({...prev, dateFrom: e })),
                                    reqiured: executionItem.saleCondition === "yes" ? true : false
                                }}
                            />
                            <InputDate
                                data={{
                                    label: "Період по",
                                    value: contractTime.dateTill,
                                    // disabled: isDraftObject( data.id ) ? false : true,
                                    onChange: (e)=> setContractTime( prev => ({...prev, dateTill: e })),
                                    reqiured: executionItem.saleCondition === "yes" ? true : false
                                }}
                        />
                    </div>
                    <InputDate
                        data={{
                            label: "Дата підписання договору",
                            value: dateSigned,
                            onChange: setDateSigned ,
                            //reqiured: true,
                            // disabled: isDraftObject( data.id ) ? false : true 
                        }}
                    />
                    
                    <div className={styles.buttonWrapper}>
                        <button 
                            className={styles.buttonCancel} 
                            onClick={ close }>
                            Скасувати
                        </button>
                        { 
                            executionItem.saleCondition === "yes" 
                            && (contractTime.dateFrom === null || contractTime.dateFrom === '')
                            && (contractTime.dateTill === null || contractTime.dateTill === '')
                                ? null
                                : <button 
                                    onClick={ createObjectData }
                                    className={ styles.buttonCreate }
                                >
                                    { !!data ? 'Оновити' : 'Додати' }
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBuyer;